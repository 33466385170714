import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faWindowClose,faUser } from '@fortawesome/free-regular-svg-icons';
import {faMapMarkerAlt,faChurch,faTrash,faUndo,faSearch,faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Logo from '../componentes/Logo'
import Parametros from '../componentes/Parametros'
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Main from '../componentes/Main';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../componentes/Modal';
import useModal from '../hooks/useModal';

const _maximoFilas = 200


export default function Nomina (){

const [datos,setDatos] = useState([])
const [copia,setCopia] = useState([])
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [iglesia,setIglesia] = useState("")
const [pastor,setPastor] = useState("")
const [localidad,setLocalidad] = useState("")
const [provincia,setProvincia] = useState(-1)
const [provincias, setProvincias] = useState([])
const [tipo,setTipo] = useState('iglesias')
const [filtro,setFiltro] = useState('')
const [contadorOperaciones,setContadorOperaciones]=useState(0)
const [limites,setLimites] = useState({minimo:0,maximo:_maximoFilas-1})
const {toggle, isShowing } = useModal();
const [idSeleccionado,setIdSeleccionado]=useState(null)

const obtenerDatos = async ()=>{
        setLimites({minimo:0,maximo:_maximoFilas-1})
        setBuscandoDatos(true)
        try{
            const {data} = await Axios.get(`https://ministros.uad.digital/api/tablasgenerales/nomina`,{params:{iglesia:iglesia,pastor:pastor,id_provincia:provincia!="-1" ? provincia : null,localidad:localidad,soloIgl:tipo==='iglesias' ? 1 : 0}})

            setDatos(data)
            setCopia(data)
            setBuscandoDatos(false)
            setContadorOperaciones(contadorOperaciones+1)

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
}

const obtenerProvincias = async ()=>{
    try{
        const {data} = await Axios.get(`https://ministros.uad.digital/api/tablasgenerales/provincias`)
        setProvincias(data)

    }catch(err){
        console.log(err)
    }
}

useEffect(()=>{
    obtenerProvincias()
},[])

useEffect(()=>{
   resetBusqueda()
},[tipo])

useEffect(()=>{
    setLimites({minimo:0,maximo:_maximoFilas-1})
    const filtroCopia = copia.filter((item)=>item.nombre.toUpperCase().includes(filtro.toUpperCase())
    || item.pastor && item.pastor.toUpperCase().includes(filtro.toUpperCase())
    || item.encargado && item.encargado.toUpperCase().includes(filtro.toUpperCase())
    || item.direccion && item.direccion.toUpperCase().includes(filtro.toUpperCase())
    || item.localidad_compl && item.localidad_compl.toUpperCase().includes(filtro.toUpperCase())
    || item.provincia && item.provincia.toUpperCase().includes(filtro.toUpperCase()))

    setDatos(filtroCopia)
 },[filtro])

function resetBusqueda(){

    setDatos([])
    setCopia([])
    setPastor("")
    setIglesia("")
    setLocalidad("")
    setFiltro("")
    setProvincia(-1)
    setContadorOperaciones(0)

}

function handleChangeTipo(e,nombre){
    setTipo(e.target.value)
}

function handleChangeIglesia(e){
    setIglesia(e.target.value)
}

function handleChangeProvincia(e){
    setProvincia(e.target.value)
}

function handleChangePastor(e){
    setPastor(e.target.value)
}

function handleChangeLocalidad(e){
    setLocalidad(e.target.value)
}

function handleChangeFiltro(e){
    setFiltro(e.target.value)
}


function borrarLocalidad(){
    setLocalidad("")
}

function borrarFiltro(){
    setFiltro("")
}

function borrarIglesia(){
    setIglesia("")
}

function borrarPastor(){
    setPastor("")
}

function iniciarBusqueda(e){
    e.preventDefault()
    if(pastor || localidad || iglesia || provincia!="-1"){
        setFiltro("")
        obtenerDatos()
    }else{
        alert('Ingrese algún valor de búsqueda')
    }
}

    if (buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    };    

    return (
        <Main center>
            <div className="flex f-col justify-center items-center mt-4">
                <Logo width={"50"}/>
                <p className="mb-4 mt-4 text-larger">Nómina de iglesias</p>
            </div>

        { isShowing && idSeleccionado && <Modal hide={toggle} isShowing={isShowing} closeOnclickOutside estiloWrapper={{background:'transparent'}}>
            <Detalle tipo={tipo} item={idSeleccionado}/>  
        </Modal>}
        <Parametros tipo={tipo} handleChangeTipo={handleChangeTipo}
                    iglesia={iglesia} handleChangeIglesia={handleChangeIglesia}
                    pastor = {pastor} handleChangePastor={handleChangePastor}
                    localidad = {localidad} handleChangeLocalidad = {handleChangeLocalidad}
                    ejecutarBusqueda = {iniciarBusqueda}
                    borrarPastor={borrarPastor}
                    borrarIglesia={borrarIglesia}
                    borrarLocalidad={borrarLocalidad}
                    provincias={provincias}
                    provincia = {provincia}
                    handleChangeProvincia={handleChangeProvincia}
                    datos = {datos}
                    contadorOperaciones = {contadorOperaciones}
                    resetBusqueda = {resetBusqueda}
                    filtro = {filtro}
                    handleChangeFiltro={handleChangeFiltro}
                    borrarFiltro = {borrarFiltro}
                    resultado = {copia.length}
        />
        <Paginacion datos={datos} setLimites={setLimites} limites={limites}/>
        <div className="rounded ml-auto mr-auto mt-4">
        <table className="table table-cent">
        <tbody>
            <FilasSimple tipo={tipo} datos ={datos} limites={limites} toggle={toggle} setIdSeleccionado={setIdSeleccionado}/>
        {/*
            datos
            .map((item,index) => {
            return (
                <tr key={uuidv4()} className={index < 10000 ? "border-bottom-solid" : "hidden"}>
                    <td>
                        <div className="flex f-col mb-4 mt-2 enc-igl">
                            <div className="flex f-row mb-4 justify-center items-center">
                                <FontAwesomeIcon className="color-tomato" icon={tipo=='iglesias' ? faChurch : faUser}/>
                                <span className="filas-lista-nw ti-nombre cursor-pointer ml-2" >
                                          <b>{item.nombre.toUpperCase()}</b>
                                </span> 
                            </div>
                            <p className="text-center tipo-n">{item.tipo}</p>
                            <div>
                                {tipo=='iglesias' && <div className="flex f-row justify-content-space-evenly">
                                    {item.pastor && <div className="ml-2"><b>Pastor : </b><span>{item.pastor}</span></div>}
                                    {item.encargado && <div className="ml-2"><b>Encargado : </b><span>{item.encargado}</span></div>}
                                </div>}
                                <br/>
                                <Ubicacion item={item}/>
                                <br/>
                                <Contacto item={item}/>
                                <br/>
                            </div>
                        </div>
                    </td>
                </tr>
            )
            })
        */}
        </tbody>
    </table>
    </div>
    </Main>
    )


}

function Detalle({tipo,item}){

    return <div className="flex f-col mb-4 mt-2 enc-igl">
                        <div className="flex f-row mb-4 justify-center items-center">
                            <FontAwesomeIcon className="color-tomato" icon={tipo=='iglesias' ? faChurch : faUser}/>
                            <span className="filas-lista-nw cursor-pointer ml-2" >
                                      <b>{item.nombre.toUpperCase()}</b>
                            </span> 
                        </div>
                        <p className="text-center tipo-n">{item.tipo}</p>
                        <div>
                            {tipo=='iglesias' && <div className="flex f-row justify-content-space-evenly">
                                {item.pastor && <div className="ml-2"><b>Pastor : </b><span>{item.pastor}</span></div>}
                                {item.encargado && <div className="ml-2"><b>Encargado : </b><span>{item.encargado}</span></div>}
                            </div>}
                            <br/>
                            <Ubicacion item={item}/>
                            <br/>
                            <Contacto item={item}/>
                            <br/>
                        </div>
                    </div>
}

function Filas({tipo,datos,limites}){

    return datos
        .filter((item,index)=>index<=limites.maximo && index>=limites.minimo)
        .map((item,index) => {
        return (
            <tr key={uuidv4()} className={index < 10000 ? "border-bottom-solid" : "hidden"}>
                <td>
                    <div className="flex f-col mb-4 mt-2 enc-igl">
                        <div className="flex f-row mb-4 justify-center items-center">
                            <FontAwesomeIcon className="color-tomato" icon={tipo=='iglesias' ? faChurch : faUser}/>
                            <span className="filas-lista-nw ti-nombre cursor-pointer ml-2" >
                                      <b>{item.nombre.toUpperCase()}</b>
                            </span> 
                        </div>
                        <p className="text-center tipo-n">{item.tipo}</p>
                        <div>
                            {tipo=='iglesias' && <div className="flex f-row justify-content-space-evenly">
                                {item.pastor && <div className="ml-2"><b>Pastor : </b><span>{item.pastor}</span></div>}
                                {item.encargado && <div className="ml-2"><b>Encargado : </b><span>{item.encargado}</span></div>}
                            </div>}
                            <br/>
                            <Ubicacion item={item}/>
                            <br/>
                            <Contacto item={item}/>
                            <br/>
                        </div>
                    </div>
                </td>
            </tr>
        )
        })
}

function Paginacion({datos,setLimites,limites}){
    const total = datos.length;

    const vectorPaginas = datos.map((item,index)=>({minimo:index,maximo:(index+(_maximoFilas-1))<total ? index+(_maximoFilas-1) : total})).filter(item=>item.minimo%_maximoFilas==0)

    console.log(vectorPaginas)

       return <div className="flex f-row justify-center">
       {vectorPaginas.map((item,index)=><button title={`Filas ${item.minimo+1} a la ${item.maximo+1}`} className={limites.minimo==item.minimo ? "b-pag-sel":"b-pag"} onClick={()=>{setLimites({minimo:item.minimo,maximo:item.maximo})}}>{index+1}</button>)}
   </div>
}

function FilasSimple({tipo,datos,limites,toggle,setIdSeleccionado}){

    return datos
        .filter((item,index)=>index <=limites.maximo && index >=limites.minimo)
        .map((item,index) => {
        return (
            <tr key={uuidv4()} className={"border-bottom-solid cursor-pointer"} onClick={()=>{setIdSeleccionado(item);toggle()}}>
                <td className="flex">
                <span className="ti-nombre">{item.nombre.toUpperCase()}</span>
                {tipo=='iglesias' && <span className="filas-lista ti-pastor">{item.pastor ? item.pastor : item.encargado }</span>}
                <span className="filas-lista"> <FontAwesomeIcon icon={faMapMarkerAlt} className="ml-2"/> {item.localidad_compl}</span>
                
                </td>
            </tr>
        )
        })
}


function Contacto({item}){
    return <div className="text-center">
        {item.telefono && <span className="ml-2"><FontAwesomeIcon icon={faPhone} className="mr-2"/>{item.telefono}</span>}
        {item.email && <span className="ml-2"><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>{item.email}</span>}
    </div>
}

function Ubicacion({item}){
    return <div className="flex f-row justify-center items-center">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2"/>
            <div className="text-center flex f-col border-dotted-gray p-2 br-10">
                {item.direccion &&<span>{`${item.direccion}`}</span>}
                {item.localidad_compl && <span>{`${item.localidad_compl}`}</span>}
                {item.provincia && <span>{`${item.provincia}`}</span>}
            </div>
        </div>
}
 
